import { onMount } from "solid-js";
import { FullScreenLayout } from "~/components/_layouts/FullScreenLayout";
import { NotFoundScreen } from "~/screens/NotFoundScreen";
import { useWire } from "~/wire";

export default () => {
  const wire = useWire();
  onMount(() => {
    wire.services.threads.send(wire.services.threads.eventFactory.newResetEvent());
  });

  return (
    <FullScreenLayout>
      <NotFoundScreen modifier="full-screen" />
    </FullScreenLayout>
  );
};
